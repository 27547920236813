.home {
    background: linear-gradient(269.67deg, #13000F 0.27%, #29000D 46.36%, #12000D 99.71%);
}

.home .home_header {
    max-height: 40px;
}

.home .home_header .g-logo {
    object-fit: contain;
    height: 32px;
}

.home .home1 {
    position: relative;
    max-width: 100%;
}

.home .home1 .bg {
    position: relative;
    width: 100%;
    z-index: 0;
}

.home .home1 .bg .layer0 {
    object-fit: cover;
    height: 1040px;
    width: 100%;
}

.home .home1 .bg .layer0.darker {
    position: absolute;
    filter: contrast(120%) saturate(120%) hue-rotate(5deg) brightness(90%);
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.home .home1 .bg .layer0.darker.show {
    opacity: 1;
}

.home .home1 .bg .tint {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    background-color: rgba(10, 0 , 120, 0.2);
    opacity: 0;
}

.home .home1 .bg .tint.show {
    opacity: 1;
}

@media (max-width: 767.98px) {
    .home .home1 .bg .layer0 {
            height: 150vw;
    }
}
.home .home1 .bg .layer1 {
    position: absolute;
    z-index: 1;
    bottom: 0px;
    width: 100%;
    height: 100%;
    /* opacity: 1; */
}

.home .home1 .bg .layer1 .img-bg {
    position: relative;
    object-fit: cover;
    min-height: 675px;
    max-height: 675px;
    /* width: 100%; */
    /* height: 100%; */
    /* height: auto; */
    opacity: 1;
}

.home .home1 .bg .layer1 .img-bg.darker {
    position: absolute;
    bottom: 0px;
    opacity: 0;
}

.home .home1 .bg .layer1 .img-bg.darker.show {
    opacity: 1;
} 

.home .home1 .bg .layer_wrapper {
    position: relative;
    /* background-color: blue; */
    width: max-content;
}

.home .home1 .bg .layer2 {
    position: relative;
    z-index: 2;
    /* width: 42.81%; */
    bottom: 0px;
    max-width: 100vw;
}

.home .home1 .bg .layer2.darker {
    position: absolute;
    z-index: 3;
    /* background-color: red; */
    left: 0px;
    opacity: 0;
}

.home .home1 .bg .layer2.darker.show {
    opacity: 1;
}

.home .home1 .layer10 {
    position: absolute;
    z-index: 10;
    top: 0px;
    left: 0px;
    z-index: 13;
}

.home .layer11 {
    position: fixed;
    z-index: 900;
    width: 100vw;
    
}

.home .layer11.toggled {
    height: 100vh;
    background: linear-gradient(269.67deg, #13000F 0.27%, #29000D 46.36%, #12000D 99.71%);
}

.home .layer11 .navtoggle {
    position: absolute;
    right: 1vw;
    top: 1vw;
    min-width: 56px !important;
    width: 15vw;
    max-width: 64px;
    border: none !important;
    box-sizing: none !important;
}

.home .home1 .copyright {
    position: absolute;
    bottom: 1px;
}




.home .layer11 .item {
    font-size: max(min(2.1vw, 40px), 32px);
}

.home .layer12 {
    position: absolute;
    z-index: 12;
    width: 100%;
    top: 0px;
    left: 0px;
}

.home .layer13 {
    position: relative;
    z-index: 900;
    width: 100vw;
    
}


.home .logo {
    /* min-width: 500px; */
    width: 520px;
    max-width: 80%;
    /* width: 26.5vw; */
}


.home .home1 .menu-box {
    width: 30%;
}

.home .home1 .menu {
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    z-index: 999;
}

.home .home1 .store {
    position: relative;
    width: 100%;
    max-width: 500px;
    /* background-color: red; */
    /* width: 26.5vw; */
}

.home .home1 .line {
    height: 8px;
    width: 100%;
    background-color: #BF2C22;
    border: solid 3px black;
}
/* .home .home1 .store .aos{
    width: 1vw !important;
} */


.home .layer11 .props {
    position: absolute;
    object-fit: contain;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.home .layer11 .prop01 {
    position: absolute;
    max-width: 60%;
    max-height: 60%;
    bottom: 0px;
    left: 0px;
}

.home .layer11 .prop02 {
    position: absolute;
    max-width: 60%;
    max-height: 60%;
    bottom: 0px;
    right: 0px;
}

.home .layer11 .prop03 {
    position: absolute;
    max-width: 20%;
    max-height: 20%;
    bottom: 55%;
    left: 0px;
}

.home .layer11 .prop04 {
    position: absolute;
    max-width: 20%;
    max-height: 20%;
    bottom: 55%;
    right: 0px;
}
.home .home2{
    max-width: min(1530px, 80%);
    /* background-color: red; */
}

.home .home2 .gamemodes {
    /* background-color:  red; */
}

.home .home2 .game-mode {
    position: relative;
    min-width: 120px;
    /* width: 30vw !important; */
    max-width: 360px;
    max-height: 560px;
}

.home .home2 .btn-info {
    position: absolute;
    z-index: 99;
    top: 4%;
    right: 9%;
    max-width: 15%;
    max-height: 15%;
    cursor: pointer;
}

.home-modal {
    background-color: #00000033;
}

.home-modal .game_detail{
    position: absolute;
    /* background-color: red; */
    width: auto;
    /* height: 100vh; */
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home-modal .game_detail .title{
    /* background-color: red; */
    font-family: 'IBM Plex Sans Thai', sans-serif;
}

.home-modal .game_detail .container{
    position: relative;
    background-color: #242223;
    color: white;    
    white-space: pre-wrap;
}

.home-modal .game_detail .container.bluebox {
    border: solid 3px #5DB7F8;
}

.home-modal .game_detail .container .context {
    text-align: start;
    font-family: 'IBM Plex Sans Thai', sans-serif;
    font-weight: 800;
    height: auto;
    /* background-color: red; */
}

.home-modal .game_detail .clickclose {
    text-align: center;
    color: white;    
    /* pointer-events: none; */
}



